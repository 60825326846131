import axios from 'axios';
import { createNavi } from './functions';
export function fetchDataFunc(url, bodyData, errorDiv) {
    let serverUrl;
    if (process.env.NODE_ENV === 'development') {
        serverUrl = process.env.VUE_APP_URL;
    } else {
        serverUrl = process.env.VUE_APP_URL_BUILD;
    }
    return axios.post(`${serverUrl}${url}`, bodyData,  {headers: {'Content-Type': 'multipart/form-data'}})
        .then(function(response){
            if (response.data.result === true) {
                return response.data.records;
            } else {
                throw new Error(response.data.message);
            }
        })
        .catch(function(error) {  
            document.getElementById(errorDiv).innerHTML = error;
            return false;
        });
}

export function prepareToFetchIt(typeOfPage, that) {
    let url = 'getJson.php';
    let formData = new FormData();
    formData.append('type', typeOfPage);

    fetchDataFunc(url, formData, 'errorDiv').then(response => {
        if (response === false) {
          return false;
        } else {
            that.startPix.text = response[0].orangeBox.innerText;
            that.startPix.title = response[0].orangeBox.title;
            that.proofJson = true;
            createContent(response, that, typeOfPage);
            createNavi(response[2], that);
            document.getElementById('mainContainer').classList.add('homeData');
        }
    });
}



export function createContent(response, that, typeOfPage) {
    switch(typeOfPage) {
        case 'foerderung':
            that.firstBox = response[0].firstBox;
            that.secondBox = response[0].secondBox;
            that.thirdBox = response[1];
            that.fourthBox = response[0].thirdBox;         
        break;   
        default:
            that.firstBox = response[0].firstBox;
            that.secondBox = response[0].secondBox;
            that.thirdBox = response[0].thirdBox;
            that.fourthBox = response[0].fourthBox;
    }
}