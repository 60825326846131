export function whichDevice() {
    if ((window.innerHeight > window.innerWidth) || ((window.innerHeight < window.innerWidth) && (window.innerWidth < 1024))) {
        return 'mobile';
    } else {
        return 'desktop';
    }
}

export function createNaviObject(navi, naviMain) {
    var completeArray = [];
    var desktop = [];
    var mobile = [];
    var subNavi = [];
    var subNaviLength;

    Object.entries(navi).forEach(value => {
        mobile.push(value[1]);
        switch(value[0]) {
            case 'home':
            case 'sprachen':
            case 'impressum':
            case 'datenschutz':
            case 'kontakt':
            case 'sitemap':
            case 'presse':
                return false;
            default: 
                desktop.push(value[1]);
        }   

        if (value[1].url === naviMain) {
            if ((Object.keys(value[1].subNavi).length > 0) || (value[1].subNavi.length > 0)) {
                subNavi = value[1].subNavi;
                subNaviLength = Object.keys(value[1].subNavi).length;
            } 
        }


    });

    completeArray = {
        'mobile': mobile, 
        'desktop': desktop,
        'subNavi': subNavi,
        'subNaviLength': subNaviLength
    };

    return completeArray;
   
}


export function createNavi(response, that) {
    let device = whichDevice();
    let navigation = createNaviObject(response.navi, that.naviBar.mainSite, that.naviBar.subSite);
    that.naviBar.burgerNavi = navigation.mobile;
    that.naviBar.mainNavi = navigation.desktop;
    that.naviBar.subNavi = navigation.subNavi;
    that.naviBar.subNaviLength = Object.keys(navigation.subNavi).length;
    that.naviBar.device = device;
}

export function getImageSize(that) {
    if (that.windowWidth === '480') return '_480.jpg';
    else if (that.windowWidth === '960') return '_960.jpg';
    else if (that.windowWidth === '1440') return '_max.jpg';
}

export function getWindowSize() {
    if (window.innerWidth < 479)  return '480';
    else if (window.innerWidth < 959)  return '960';
    else return '1440';
}

export function toggleText(id, idMain) {
    let allElements = document.getElementsByClassName(idMain);
    Object.entries(allElements).forEach(value => {
        if (value[1].children[1].id === id) {
            if (value[1].children[1].classList.contains('collapsed')) {
            value[1].children[1].classList.remove('collapsed');
            window.setTimeout(function() {
                value[1].children[1].style.display = 'none';
            }, 150);
            value[1].children[0].children[1].style.display = 'block';
            value[1].children[0].children[2].style.display = 'none';
            } else {
            value[1].children[1].style.display = 'block';
            window.setTimeout(function() {
                value[1].children[1].classList.add('collapsed');
            }, 10);
            value[1].children[0].children[1].style.display = 'none';
            value[1].children[0].children[2].style.display = 'block';
            }
        } else {
            value[1].children[1].classList.remove('collapsed');
            window.setTimeout(function() {
            value[1].children[1].style.display = 'none';
            }, 150);
            value[1].children[0].children[1].style.display = 'block';
            value[1].children[0].children[2].style.display = 'none';
        }
    });
}