//redefine console.log
console.log = function() {};

import './../css/font.scss';
import './../css/style.scss';

import { createApp } from 'vue';
import VueSmoothScroll from 'vue3-smooth-scroll';
import FooterBar from './../components/FooterBar.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPhone, faPhoneFlip, faArrowRightLong, faSquareCaretUp, faBars, faDownload, faPlus, faMinus, faEnvelope, faGlobe, faCookie } from '@fortawesome/free-solid-svg-icons';


library.add(faPhone, faPhoneFlip, faArrowRightLong, faSquareCaretUp, faBars, faDownload, faPlus, faMinus, faEnvelope, faGlobe, faCookie);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

createApp(FooterBar).component('font-awesome-icon', FontAwesomeIcon).use(VueSmoothScroll).mount('#footer');







